.project-deadline {
    width: 100%;
    height: 100%;
    margin-top: 0px;
    box-sizing: border-box;
    color: var(--white);

    display: flex;
    flex-direction: row;
    align-items: stretch;
}

.project-deadline .deadline-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.deadline-icon,
.deadline-icon::before,
.deadline-icon::after {
    box-sizing: unset;
}

.project-deadline .deadline-data,
.project-deadline .deadline-calendar-icon {
    padding: 20px;
}

.project-deadline .deadline-calendar-icon {
    width: 44px;
    height: 44px;
}

.project-deadline .deadline-data {
    width: 100%;
}

.project-deadline .deadline-data-title {
    color: var(--white);
}

.project-deadline .deadline-data-info {
    font-family: Roboto;
    font-size: 40px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: left;
    color: var(--white);
}

.project-deadline .deadline-on-time {
    background-color: var(--green);
}

.project-deadline .deadline-less-than-twenty-time {
    background-color: var(--pumpkin-orange);
}

.project-deadline .deadline-out-of-time {
    background-color: var(--red);
}

.project-deadline .deadline-on-time-icon {
    background-color: var(--true-green);
}

.project-deadline .deadline-less-than-twenty-icon {
    background-color: var(--pumpkin);
}

.project-deadline .deadline-out-of-time-icon {
    background-color: var(--deep-red);
}