.info-container {
  display: flex;
  flex-direction: column;
  padding: 1px 16px 28px 16px;
  flex-wrap: wrap;
}

.info-container .info-header {
  padding-bottom: 8px;
  display: flex;
  flex: 1;
  flex-direction: row;
}

.info-container .info-subheader {
  padding-bottom: 8px;
}

.info-container .info-step,
.info-container .info-date,
.info-container .info-dot,
.info-container .info-edited {
  display: flex;
  vertical-align: -webkit-baseline-middle;
  margin-top: 0.5em;
}

.info-container .info-icon {
  padding: 5px;
  color: #4192d9;
}

.info-container .info-step {
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  font-weight: 500;
  color: #4192d9;
}

.info-container .info-date {
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #8b8b8b;
}

.info-container .info-edited-dot {
  font-size: 6px;
  color: #8b8b8b;
  margin-left: 5px;
  margin-right: 5px;
}

.info-container .info-edited-label {
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #6b778c;
  margin-top: -0.2em;
}

.info-container .info-dot {
  font-size: 6px;
  color: #8b8b8b;
  padding: 5px;
}

.info-container .info-author {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #313131;
}

.info-container .info-message {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #626262;
  white-space: pre-wrap;
  flex-wrap: wrap;
  padding-bottom: 0.5em
}

.info-container .info-icons {
  display: flex;
  flex: 1;
  margin-top: 0.4em;
}

.info-container .info-icons p {
  color: #878787;
}

.info-container .info-icons p:hover {
  cursor: pointer;
  text-decoration: underline;
}