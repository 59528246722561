.task-list {
    background-color: #f5f5f5;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.task-list .list-task {
    width: 100%;
    flex: 1 1 auto;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}

/* width */
.task-list .list-task::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.task-list .list-task::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 10px;
}

/* Handle */
.task-list .list-task::-webkit-scrollbar-thumb {
    background-color: #28456c;
    border-radius: 10px;
}

/* Handle on hover */
.task-list .list-task::-webkit-scrollbar-thumb:hover {
    background-color: #28456c;
}

.task-list .list-task-item {
    height: auto;
    border-top: 1px solid #385cac;
    border-bottom: 1px solid #2851aa;
    margin: -1px;
    background-color: var(--very-light-blue);
    padding: 16px;
}

.task-list .list-task-item-selected {
    background-color: #b5dcfc;
    display: block;
    width: 100%;
    height: auto;
    color: var(--white);
    padding: 16px;
    margin: -16px;
}

.task-list .tablerow-currentuser-assigned {
    background-color: rgb(238, 233, 229);
}

.task-list .list-task-item:hover {
    background-color: #bfddf7;
}

.task-list .list-task-item-row {
    padding-bottom: 10px;
}

.list-task-item,
.list-task-item::before,
.list-task-item::after {
    box-sizing: unset;
}

.task-list .status-icon {
    width: 16px;
    height: 16px;
}

.task-list .assigned-flag {
    color: #1f9bc0;
    padding-left: 8px;

}

.task-list .task-icon {
    padding-left: 8px;
    padding-right: 8px;
}

.task-list .task-number {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: #091b4b;

    padding-left: 14px;
}

.task-list .filter-task-deadline {
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #4e6085;

    float: right;
}

.task-list .task-customer {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: #091b4b;

    padding-left: 14px;
}

.task-list .task-study-type {
    font-family: Roboto;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: #091b4b;
    padding-left: 14px;
}

/* .task-list .new-task {
    flex: 0 0 auto;
    padding-bottom: 32px;
    padding-top: 32px;
    padding-left: 92px;
    padding-right: 92px;
    background-color: #e6ecf2;
    text-align: center;
} */

.task-list .fix {
    padding: 0.1em;
}

.task-list .list-task-item-label {
    padding-left: 27px;
    display: flex;
    flex-wrap: wrap;
}

.task-list .label-margin-left {
    margin-left: 10px;
}

.task-list .label-margin-right {
    margin-right: 10px;
}

.task-list .label-pendency {
    background-color: var(--pumpkin-orange);
    color: var(--white);
    font-family: Roboto;
    font-size: 9px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: 0.5px;
}

.task-list .label-deadline {
    color: var(--white);
    float: right;
}

.task-list .label-deadline-ontime {
    background-color: var(--true-green);
}

.task-list .label-deadline-approaching {
    background-color: var(--pumpkin);
}

.task-list .label-deadline-overdue {
    background-color: var(--red);
}

.task-list .listCounter {
    padding-top: 7px;
    padding-left: 15px;
    padding-right: 8px;
    padding-bottom: 8px;
    font-size: 12px;
    font-family: Roboto;
    color: #747474;
}