.created-users-form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.created-users-form .header {
    min-width: 20em;
}

.created-users-form .select-container {
    display: -ms-flexbox;
    /* IE10 */
    display: flex;
    border: 1px solid #e6ecf2;
    flex: 0 0 auto;
}

.created-users-form .select-field {
    width: 100%;
    padding-left: 16px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: Roboto;
    background-color: #e6ecf2;
    outline: none;
    border: none;
}

.created-users-form .select-field-icon {
    color: #385cac;
}

.created-users-form .select-field-button {
    background-color: #e6ecf2;
    text-align: center;
    padding: 22px;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.created-users-form .header .header-title {
    width: 100%;
    font-family: "Roboto";
    font-size: 1.625em;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.15;
    letter-spacing: normal;
    color: var(--black);
    margin-left: 1em;
    margin-top: 1em;
}

.created-users-form .content {
    width: 100%;
    flex: 1 1 auto;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}

.created-users-form .content::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.created-users-form .content::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 10px;
}

/* Handle */
.created-users-form .content::-webkit-scrollbar-thumb {
    background-color: #28456c;
    border-radius: 10px;
}

/* Handle on hover */
.created-users-form .content::-webkit-scrollbar-thumb:hover {
    background-color: #28456c;
}

.created-users-form .new-user {
    flex: 0 0 auto;
    padding: 32px;
    background-color: #e6ecf2;
    text-align: center;
    position: static;
}