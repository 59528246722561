.home {
  background-color: #ededed;
  display: flex;
  flex-direction: column;
  background-size: auto;
  justify-content: flex-start;
  min-height: 100vh;
}

.home h1 {
  width: 215px;
  height: 30px;
  font-family: Roboto;
  font-size: 26px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);
  margin-bottom: 2em;
}

.containers {
  padding: 2em 5em;
  max-width: 960px;
  display: flex;
  flex-direction: column;
}

.container {
  flex: 1;
  align-content: stretch;
}

.container-registrations {
  margin-top: 3em;
}

.item {
  background-color: #fff;
  border-radius: 5%;
  float: center;
  text-align: center;
  vertical-align: center;
  border: 1px solid #dbd9d9;
  padding: 1em;
}

.item .icon {
  font-size: 2em;
  height: 2.5em;
  color: #4192d9;
}

.item:hover {
  cursor: pointer;
}

.item p {
  text-align: center;
  color: #565454;
}

.foot {
  align-items: flex-end;
}

.foot a {
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 900;
  font-family: "Open Sans";
  padding-right: 1em;
  color: #a09f9f;
  float: right;
  margin-bottom: 0.5em;
}

.foot a:hover {
  color: #515050;
  transition: 0.5s;
}