.project-create-form {
    padding: 35px 75px 35px 75px;
}

.project-create-form .header-title {
    width: 215px;
    height: 30px;
    font-family: Roboto;
    font-size: 26px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: var(--black);
}

.project-create-form .header-actions {
    width: 600px;
    display: flex;
    justify-content: flex-end;
}

.project-create-form .content {
    padding: 0px;
}

.project-create-form .footer {
    padding-top: 30px;
}

.project-create-form .project-data,
.customer-data,
.project-create-form .project-studies,
.project-create-form .project-payments-terms {
    margin-top: 50px;
    display: block;
}

.project-create-form .racp-number,
.project-create-form .customer-name,
.project-create-form .study,
.project-create-form .campaign,
.project-create-form .deadline,
.project-create-form .project-value,
.project-create-form .project-payment-date,
.project-create-form .payment-condition,
.project-create-form .instalments {
    padding-top: 15px;
}

.project-create-form .header,
.project-create-form .customer-name,
.project-create-form .study-area,
.project-create-form .project-study-item,
.project-create-form .project-payments-terms-item {
    display: flex;
}

.project-create-form .invoicing {
    width: 540px;
    padding: 15px 20px;
    margin-top: 40px;
    background-color: var(--white-two);
}

.project-create-form .project-studies-add-icon,
.project-create-form .project-studies-create-icon {
    padding-right: 16px;
}

.project-create-form .project-studies-create-icon {
    color: var(--dark-seafoam-green);
}

.project-create-form .project-studies-add-icon {
    color: #4192d9;
}

.project-create-form .project-studies-actions {
    display: flex;
    width: 330px;
    padding-top: 35px;
    justify-content: space-between;
    box-sizing: initial;
}

.project-create-form .remove-study-item,
.project-create-form .customer-new-form,
.project-create-form .customer-new-study-area {
    display: flex;
    flex-direction: column-reverse;
}

.project-create-form .remove-study-icon {
    width: 18px;
    height: 18px;
    color: var(--red);
}

.project-create-form .form-errors {
    padding-top: 35px;
}