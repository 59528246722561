.login {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items: center;
    justify-content: flex-start;
    background: #ebebeb;
    background-repeat: no-repeat;
    background-size: cover;
}

.login-messages {
    padding-bottom: 2em;
}

.login-container {
    margin-top: 6em;
    width: 416px;
}

.login-logo-container {
    padding-bottom: 3em;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.login-logo {
    display: block;
    flex-grow: 1;
}

.login-logo:hover {
    opacity: 1;
}

.login-title {
    font-family: Roboto;
    font-size: 48px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: var(--marine-blue);
    flex-grow: 11;
}

.login-form {
    padding: 40px 60px;
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.26);
    background-color: var(--white);
}

.login-form-row {
    margin-bottom: 2em;
}

.login-form-actions {
    margin-top: 1em;
    margin-bottom: 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.login-password-recover {
    flex-grow: 1;
}

.login-submit-buttom {
    float: "right";
    width: "90px";
    height: "36px";
    border-radius: "2px";
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24);
    background-color: var(--dark-seafoam-green);

}

.login-submit-buttom:hover {
    background-color: var(--green);
    border-color: #385cac;
}

.login-password-recover a {
    color: #4192d9;
}