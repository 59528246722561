.project-info {
    display: block;
}

.project-info .project-info-message {
    padding-top: 15px;
}

.project-info .project-info-actions {
    display: flex;
    align-items: center;
    padding-top: 35px;
}

.project-info .actions-pendency {
    flex-grow: 11;
    display: flex;
    align-items: center;
}

.project-info .actions-save {
    justify-content: flex-end;
    flex-grow: 1;
    display: flex;
}