.sidebar-item {
    padding: 12px 24px;
    color: var(--white);
    display: flex;
    align-items: center;
}

.sidebar-item-icon {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    color: var(--white);
}

.sidebar-item-text {
    padding: 0px 16px;
    flex: auto;
}

.sidebar-item-text a,
a:link,
a:visited,
a:focus,
a:hover,
a:active {
    color: var(--white);
    text-decoration: none;
    cursor: pointer;
}

.sidebar-item-selected {
    display: block;
    width: 100%;
    height: 100%;
    color: var(--white);
    background-color: var(--marine-blue);
}

.sidebar-item:hover {
    background-color: var(--marine-blue);
}

.sidebar-subitem-container {
    background-color: #004078;
    color: var(--white);
}

.sidebar-subitem {
    padding-left: 56px;
    padding-top: 12px;
    padding-bottom: 12px;
}

.sidebar-subitem:hover {
    background-color: var(--marine-blue);
}

.expand {
    -webkit-animation: expand 1s;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: expand 1s;
    /* Firefox < 16 */
    -ms-animation: expand 1s;
    /* Internet Explorer */
    -o-animation: expand 1s;
    /* Opera < 12.1 */
    animation: expand 1s;
}

@keyframes expand {
    from {
        opacity: 0;
        transform: translate3d(0, -5%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.retract {
    -webkit-animation: retract 1s;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: retract 1s;
    /* Firefox < 16 */
    -ms-animation: retract 1s;
    /* Internet Explorer */
    -o-animation: retract 1s;
    /* Opera < 12.1 */
    animation: retract 1s;
}

@keyframes retract {
    from {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    to {
        opacity: 0;
        transform: translate3d(0, -5%, 0);
    }
}

@-webkit-keyframes rotatingCw

/* Safari and Chrome */
    {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}

@keyframes rotatingCw {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -ms-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}

.rotatingCw {
    -webkit-animation: rotatingCw 1s;
    -moz-animation: rotatingCw 1s;
    -ms-animation: rotatingCw 1s;
    -o-animation: rotatingCw 1s;
    animation: rotatingCw 1s;
}

@-webkit-keyframes rotatingAcw

/* Safari and Chrome */
    {
    from {
        -webkit-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    to {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

@keyframes rotatingAcw {
    from {
        -ms-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    to {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

.rotatingAcw {
    -webkit-animation: rotatingAcw 1s;
    -moz-animation: rotatingAcw 1s;
    -ms-animation: rotatingAcw 1s;
    -o-animation: rotatingAcw 1s;
    animation: rotatingAcw 1s;
}