.project-history-form {
    min-width: 815px;
    padding: 35px 75px 35px 75px;
}

.project-history-form .header-title {
    height: 30px;
    font-family: Roboto;
    font-size: 26px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: var(--black);
}

.project-history-form .content {
    padding-top: 35px;
}

.project-history-form .footer {
    padding-top: 55px;
}

.project-history-form .line {
    padding: 10px;
}

.project-history-form .days-info {
    padding-top: 10px;
}