.project-edit-form .form {
    min-width: 815px;
    padding: 35px 75px 35px 75px;
}

.project-edit-form .header-title {
    width: 215px;
    height: 30px;
    font-family: Roboto;
    font-size: 26px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: var(--black);
}

.project-edit-form .header-actions {
    width: 600px;
    display: flex;
    justify-content: flex-end;
}

.project-edit-form .content {
    padding: 0px;
}

.project-edit-form .footer {
    padding-top: 30px;
}

.project-edit-form .project-data,
.project-edit-form .project-studies,
.project-edit-form .project-payments-terms {
    margin-top: 55px;
    display: block;
}

.project-edit-form .racp-number,
.project-edit-form .customer-name,
.project-edit-form .study,
.project-edit-form .campaign,
.project-edit-form .deadline,
.project-edit-form .project-value,
.project-edit-form .project-payment-date,
.project-edit-form .payment-condition,
.project-edit-form .instalments {
    padding-top: 15px;
}

.project-edit-form .deadline-date {
    padding-top: 1em
}

.project-edit-form .header,
.project-edit-form .customer-name,
.project-edit-form .study,
.project-edit-form .project-study-item,
.project-edit-form .project-payments-terms-item {
    display: flex;
}

.project-edit-form .invoicing {
    width: 540px;
    padding: 15px 20px;
    margin-top: 40px;
    background-color: var(--white-two);
}

.project-edit-form .form-errors {
    padding-top: 35px;
}

.project-edit-form .icon {
    display: flex;
    flex-direction: column-reverse;
    padding: 5px;
    color: var(--red);
}