.task-details {
    background-color: #e6ecf2;
    padding: 20px 15px;
}

.task-details .details-title {
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: #091b4b;
}

.task-details .details-item {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: #091b4b;
    padding-right: 15px;

}

.task-details .details-value {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: var(--dusk);
}

.task-details .details-item,
.task-details .details-value {
    padding-top: 18px;
}