.user-create-form {
    display: flex;
    flex-direction: column;
    padding: 2.188em 4.688em 2.188em 4.688em;
    height: auto;
    width: 100%;
    overflow: auto;
}

.user-create-form .header-title {
    font-family: "Roboto";
    font-size: 1.625em;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: var(--black);
}

.user-create-form .header-actions {
    width: 600px;
    display: flex;
    justify-content: flex-end;
}

.user-create-form .content {
    padding: 0px;
}

.user-create-form .user-data {
    margin-top: 55px;
}

.user-create-form .content .permissions-data .addPermissionsButton {
    color: red;
}

.user-create-form .departments-data {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.user-create-form .departments-data .department .department-icons {
    flex: 1;
    float: right;
    margin-top: 1em;
}

.user-create-form .departments-data .department .department-icons .remove-department-form-icon {
    color: var(--red);
}

.user-create-form .projects-permissions-data {
    margin-top: 3em;
}

.user-create-form .projects-permissions-data .projects-permissions-content {
    margin-top: 1.2em;
}

.user-create-form .projects-permissions-data .projects-permissions-content .duallist-titles {
    display: flex;
}

.user-create-form .projects-permissions-data .projects-permissions-content .duallist-box {
    height: 25vh;
}

.user-create-form .projects-permissions-data .projects-permissions-content .available-phases-title {
    font-size: 0.9em;
    margin-left: 0.3em;
    color: #4e4e4e;
}

.user-create-form .projects-permissions-data .projects-permissions-content .selected-phases-title {
    font-size: 0.9em;
    margin-left: 23.2em;
    color: #4e4e4e;
}

.user-create-form .projects-permissions-data .projects-permissions-content .available-roles-title {
    font-size: 0.9em;
    margin-left: 0.3em;
    color: #4e4e4e;
}

.user-create-form .projects-permissions-data .projects-permissions-content .selected-roles-title {
    font-size: 0.9em;
    margin-left: 24em;
    color: #4e4e4e;
}

.user-create-form .content .submit-data {
    padding-top: 2em;
    padding-left: 0.5em;
    display: flex;
    flex-direction: row;
    margin-bottom: 3em;
}

.user-create-form .content .submit-data .deleteButton {
    margin-left: 10em;
    margin-bottom: 3em;
}

.user-create-form .form-errors {
    margin-top: 3em;
}