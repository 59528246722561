.users-form {
    display: flex;
    width: 100%;
    margin-top: 0px;
    box-sizing: border-box;
}

.users-form .created-users-form {
    min-width: 25%;
    height: 100vh;
    flex: 1 1 0px;
    background-color: #e6ecf2;

    overflow-x: hidden;
    overflow-y: auto;
}

.users-form .new-user-form {
    width: 75%;
    display: flex;
    height: 100vh;
    -webkit-box-pack: start;
    justify-content: flex-start;
    position: sticky;
    top: 0px;
    z-index: 500;
    overflow-y: hidden;
    overflow-x: hidden;
    direction: ltr;
}