@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500");

html,
body {
    height: 100vh;
    font-family: "Roboto", sans-serif;
    /* overflow-y: hidden; */
}

body {
    margin: 0;
}

:root {
    --black: #000000;
    --greyish-brown: #4e4e4e;
    --brownish-grey: #747474;
    --white-two: #f7f7f7;
    --white: #ffffff;
    --marine-blue: #00305a;
    --darkish-blue: #004b8d;
    --dusk: #596585;
    --greyblue: #659ccc;
    --very-light-blue: #dbedfc;
    --true-green: #0e8d0e;
    --green: #1aa81a;
    --dark-seafoam-green: #3fb165;
    --deep-red: #9d0303;
    --red: #c70000;
    --pumpkin: #ed6d00;
    --pumpkin-orange: #ff8900;

    --brown-main: #c86101;
    --brown-light: #d78956;
    --brown-dark: #8d4200;
    --brown-contrast-text: #fff;

    --purple-main: #5125af;
    --purple-light: #8767c6;
    --purple-dark: #2a1399;
    --purple-contrast-text: #fff;

    --info-main: #0288d1;
    --info-light: #03a9f4;
    --info-dark: #01579b;
    --info-contrast-text: #fff;

    --warning-main: #ed6c02;
    --warning-light: #ff9800;
    --warning-dark: #e65100;
    --warning-contrast-text: #fff;

    --error-main: #d32f2f;
    --error-light: #ef5350;
    --error-dark: #c62828;
    --error-contrast-text: #fff;

    --success-main: #2e7d32;
    --success-light: #4caf50;
    --success-dark: #1b5e20;
    --success-contrast-text: #fff;


}

h1 {
    font-family: Roboto;
    font-size: 26px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;

    display: block;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.Text-Style {
    font-family: Roboto;
    font-size: 26px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: var(--white);
}

h2 {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
}

h3 {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
}

h4 {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
}

h5 {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: var(--greyish-brown);

    display: block;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

p {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;

    display: block;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

small {
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
}

.flex-container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100%;
}

.container {
    display: flex;
    flex: 1 1 auto;
}

.content {
    position: relative;
    z-index: 1;
    min-width: 0px;
    flex: 1 1 auto;
}

.nav {
    width: 240px;
    display: flex;
    height: 100vh;
    -webkit-box-pack: start;
    justify-content: flex-start;
    position: sticky;
    top: 0px;
    z-index: 500;
}

a {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: #4192d9;
}

.status-red {
    color: var(--red);
}

.status-green {
    color: var(--green);
}

.status-orange {
    color: var(--pumpkin-orange);
}

.status-dusk {
    color: var(--dusk);
}

.status-icon {
    width: 16px;
    height: 16px;
}

.label,
.badge {
    background-color: #b5b5b5;
    color: #fff;
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 2;
    font-weight: bold;
    padding: 3px 7px;
    text-align: center;
    vertical-align: baseline;
    white-space: nowrap;
    text-transform: uppercase;
}

.label {
    border-radius: 4px;
    display: inline;
    /* margin: 3px; */
}


.label-task-incompleted,
.label-orange {
    background-color: var(--pumpkin);
    color: var(--white);
    font-family: Roboto;
    font-size: 9px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: 0.5px;
}

.label-red,
.label-cancelled {
    background-color: var(--red);
    color: var(--white);
    font-family: Roboto;
    font-size: 9px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: 0.5px;
}

.label-grey,
.label-released {
    background-color: var(--dusk);
    color: var(--white);
    font-family: Roboto;
    font-size: 9px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: 0.5px;
}


.label-pendency {
    background-color: var(--pumpkin-orange);
    color: var(--white);
    font-family: Roboto;
    font-size: 9px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: 0.5px;
}

.label-in-progress {
    background-color: var(--darkish-blue);
    color: var(--white);
    font-family: Roboto;
    font-size: 9px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: 0.5px;
}

.label-green,
.label-completed,
.label-confirmed {
    background-color: var(--true-green);
    color: var(--white);
    font-family: Roboto;
    font-size: 9px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: 0.5px;
}

.label-task-cancelled {
    /* background-color: var(--greyish-brown); */
    color: var(--white);
    font-family: Roboto;
    font-size: 9px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: 0.5px;
}

.badge {
    border-radius: 10px;
    display: inline-block;
    padding: 1px 7px;
}

.label[href]:hover,
.label[href]:focus {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
}

a.badge:hover,
a.badge:focus {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
}

.label a,
.badge a {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
}

.label:empty,
.badge:empty {
    display: none;
}

.label-default,
.bg-default {
    background-color: #bcd3e6;
    color: #4e6085;
    font-family: Roboto;
    font-size: 9px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: 0.5px;
    text-align: left;
}

.bg-red {
    background-color: red;
}

.bg-orange {
    background-color: var(--pumpkin-orange);
}

.bg-green {
    background-color: green;
}

.datatable-icon-column {
    display: flex;
}

/* Required for react-photo-album fullscreen mode to overlay the app menu */
.ReactModal__Overlay--after-open {
    z-index: 10000 !important;
}