.complete-task-dialog .complete-task-details {
    display: flex;
    width: 600px;
    padding-bottom: 24px
}

.complete-task-dialog .complete-task-details dl {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
}

.complete-task-dialog .complete-task-details dt {
    width: 40%;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: #091b4b;
    padding-top: 1em;
}

.complete-task-dialog .complete-task-details dd {
    display: flex;
    margin: 0;
    width: 60%;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: var(--dusk);
    padding-top: 1em;
}

.complete-task-dialog .complete-task-overview {
    margin-top: 15px;
}