.project-filter {
    flex: 1;
    display: flex;
    padding: -2px;
    flex-flow: column;
}

/* .project-filter .select-container {
    display: -ms-flexbox;
    display: flex;
    border: 1px solid #e6ecf2;
    flex: 0 0 auto;
    width: 100%;
    margin: -1px;
} */

.project-filter .select-field {
    width: 100%;
    padding-left: 16px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: Roboto;
    background-color: #e6ecf2;
    outline: none;
    border: none;
}

/* .project-filter .select-container:hover {
    border: 1px solid #385cac;
} */

.project-filter .select-field-icon {
    color: #385cac;
}

.project-filter .select-field-button {
    background-color: #e6ecf2;
    text-align: center;
    padding: 12px;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.project-filter .select-field-filter {
    background-color: #bfddf7;
}

.project-filter .select-container input:focus,
.project-filter .select-container button:focus {
    outline: none;
}

.project-filter .advanced-container {
    background-color: #f2f5f7;
}

.project-filter .advanced-filters {
    display: flex;
    padding: 14px;
    flex-flow: column;
}

.project-filter .advanced-filters-active {
    color: var(--darkish-blue);
}

.project-filter .advanced-actions {
    display: flex;
    float: right;
    padding: 10px;
}

.project-filter .advanced-action {
    padding: 4px;
}

.project-filter .advanced-action {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: var(--darkish-blue);
}