.project-data {
    display: flex;
    width: 100%;
    margin-top: 0px;
    box-sizing: border-box;
}

.project-data .data-content {
    padding: 35px 70px;
    min-width: 0px;
    min-height: 100%;
    flex: 1 1 0px;
}

.project-data .data-sidebar {
    display: flex;
    min-height: 100vh;
    -webkit-box-pack: end;
    justify-content: flex-end;
    position: sticky;
    top: 0px;
    z-index: 500;
    max-width: 380px;
    background-color: var(--white-two);
}

.project-data .header-title {
    font-family: Roboto;
    font-size: 26px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: var(--black);
}

.project-data .project-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 30px;
}

.project-data .project-subheader {
    padding-bottom: 20px;
}

.project-data .header-title {
    flex-grow: 11;
}

.project-data .header-actions {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    flex-grow: 1;
}

.project-data .project-number {
    opacity: 0.69;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: var(--black);
    padding-bottom: 16px;
}

.project-data .project-customer {
    opacity: 0.69;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: var(--black);
    padding-bottom: 16px;
}

.project-data .project-customer:hover {
    cursor: pointer;
}

.project-data .line {
    margin: 5px 0 5px 0;
}

.project-data .project-actions {
    display: flex;
    justify-content: flex-end;
    -webkit-align-items: center;
    align-items: center;
    min-height: 60px;
}

.project-data .project-footer {
    padding-top: 35px;
    padding-bottom: 35px;
}