.task-checklist {
    display: block;
}

.task-checklist .row {
    display: flex;
    flex-direction: row;
}

.task-checklist h5 {
    flex-grow: 11;
}

.task-checklist .row .icon {
    color: #4e4e4e;
}

.task-checklist .row .icon:hover {
    cursor: pointer;
}

.task-checklist .checklist-phases,
.task-checklist .checklist-tabs,
.task-checklist .execution-info {
    padding-top: 20px;
}

.task-checklist .execution-info {
    padding-left: 20px;
    display: flex;
}

.task-checklist .execution-info dl {
    margin: 0;
}

.task-checklist .execution-info dt {
    float: left;
    clear: left;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: #091b4b;
    padding-top: 1em;
    width: 100px;
}

.task-checklist .execution-info dd {
    display: flex;
    margin: 0 0 0 110px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: var(--dusk);
    padding-top: 1em;
}

.task-checklist .phase-name {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    padding-left: 5px;
    text-transform: uppercase;
    width: 100%;
}

.task-checklist .phase-name-active {
    color: "#4192d9";
}

.task-checklist .phase-name-default {
    color: var(--brownish-grey);
}

.task-checklist .phase-progress-indicator {
    width: auto;
}

.task-checklist .task-name {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #6e6e6e;
    width: 100%;
    flex-grow: 11;

    padding-left: 10px;
    padding-right: 10px;
}

.task-checklist .task-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-grow: 1;
    align-items: center;
}

.task-checklist .task-not-relevant-icon {
    color: #a7a7a7;
}

.task-checklist .task-not-relevant-item {
    text-decoration: line-through;
}

.task-checklist .task-item-loading {
    color: darkgrey;
    font-style: italic;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
}