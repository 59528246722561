.notification-list {
    background-color: #f5f5f5;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.notification-list .list-notification {
    width: 100%;
    flex: 1 1 auto;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}