.new-customer-form .customer-data .row {
    display: flex;
    flex-wrap: wrap;
}
.new-customer-form .content .submit-data {
    padding-top: 2em;
    padding-left: 0.5em;
    display: flex;
    flex-direction: row;
}
.new-customer-form .content .deleteButton {
    margin-left: 10em;
}
