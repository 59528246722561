.project-infos {
  background-color: var(--white-two);
  display: flex;
  min-height: 100vh;
  justify-content: flex-end;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 500;
}

.project-infos .infos-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.project-infos .infos-title {
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #4c4c4c;

  padding: 28px 16px 16px 16px;

  display: flex;
  flex: 0 0 auto;
}

.project-infos .infos.items {
  width: 100%;
  flex: 1 1 auto;
}
