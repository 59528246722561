.reasons-form {
    display: flex;
    width: 100%;
    margin-top: 0px;
    box-sizing: border-box;
}

.reasons-form .created-reasons-form {
    min-width: 25%;
    height: 100vh;
    flex: 1 1 0px;
    background-color: #e6ecf2;

    overflow-x: hidden;
    overflow-y: hidden;
}

.reasons-form .new-reason-form {
    width: 75%;
    display: flex;
    height: 100vh;
    -webkit-box-pack: start;
    justify-content: flex-start;
    position: sticky;
    top: 0px;
    z-index: 500;
    direction: ltr;
}